import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

function ContinueButton({ ariaLabel, onClick, text }) {
  return (
    <div className={styles.container}>
      <button
        aria-label={ariaLabel}
        className={styles.button}
        onClick={onClick}
        type="submit"
      >
        {text}
      </button>
    </div>
  );
}

ContinueButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string
};

export default ContinueButton;
