import appConfig from '#/config/app';

const { sam3 } = appConfig;

const openLoginRedirect = () => {
  const { loginURL, redirectURL, clientID } = sam3;

  const redirectURI = redirectURL;
  const url = new URL(loginURL);

  url.searchParams.set('response_type', 'code');
  url.searchParams.set('client_id', clientID);
  url.searchParams.set('redirect_uri', redirectURI);
  url.searchParams.set('scope', 'remote-login');

  window.open(url, '_self');
};

export default openLoginRedirect;
