import React from 'react';
import Header from '#/components/Header/Header';
import Footer from '#/components/Footer/Footer';
import useQuery from '#/helpers/useQuery';
import CodeInputContainer from '#/containers/CodeInputContainer/CodeInput';
import ConfirmationContainer from '#/containers/ConfirmationContainer/Confirmation';
import { STORAGE } from '#/constants';
import openLoginRedirect from '#/helpers/openLoginRedirect';
import storage from '#/services/storage/storage';

import styles from './ViewContainer.module.scss';

const { STORAGE_LOGIN_CODE } = STORAGE;

function ContainerRender() {
  const query = useQuery();
  const loginCode = query.get('login_code');

  // on QR-code opening site. Save login code and go directly to SAM3 login
  if (loginCode) {
    // Save login code value to localstorage to use after login redirect back to this webapp
    storage.set(STORAGE_LOGIN_CODE, loginCode);

    // open SAM3 login page for login/redirect to this webapp with token code
    if (storage.get(STORAGE_LOGIN_CODE)) {
      openLoginRedirect();
    }

    return <div />;
  }

  // on returning after SAM3 login redirect, show confirmation page
  if (query.get('code')) {
    return <ConfirmationContainer />;
  }

  return <CodeInputContainer />;
}

function ViewContainer() {
  return (
    <div className={styles.viewport}>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <ContainerRender />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ViewContainer;
