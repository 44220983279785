import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputMessage.module.scss';

function InputMessage({ message, open }) {
  return (
    <div
      className={`${styles.container} ${open ? styles.open : ''}`}
      role="alert"
    >
      <div className={styles.message}>{message}</div>
    </div>
  );
}

InputMessage.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool
};

export default InputMessage;
