/**
 * Adds new or updates existing URL params on specified URL.
 * @param {object} opts Options
 * @param {object} opts.params Params object - keys, values
 * @param {object} opts.url Non encoded URL
 * @returns {String} The url with replaced query params
 */
 const addOrUpdateUrlParams = ({ params = {}, url }) => {
  if (!url) {
    return '';
  }

  try {
    const urlClass = new URL(url);

    Object.keys(params).forEach(key => {
      if (params[key] !== undefined && params[key] !== null) {
        urlClass.searchParams.set(key, params[key]);
      }
    });

    return urlClass.href;
  } catch (e) {
    console.warn('Invalid URL', e);

    return url;
  }
};

export default addOrUpdateUrlParams;
