import addOrUpdateUrlParams from './addOrUpdateUrlParams';
import encodeQueryData from './encodeQueryData';

const DEFAULT_TIMEOUT = 3; // seconds
const CONTENT_TYPE = 'Content-Type';

export const request = ({
  endpoint: url,
  opts = {},
  timeout: requestTimeout
} = {}) => {
  const {
    body = {},
    headers = {},
    method = 'GET',
    timeout = requestTimeout || DEFAULT_TIMEOUT,
    oauth
  } = opts;

  const queryData = {
    ...opts.queryData
  };

  const requestOpts = {
    headers,
    method
  };

  if (!url) {
    return Promise.resolve();
  }

  if (method === 'POST') {
    if (oauth) {
      requestOpts.body = encodeQueryData(body);
    } else {
      const jsonType = 'application/json';

      requestOpts.headers[CONTENT_TYPE] = headers[CONTENT_TYPE] || jsonType;
      requestOpts.body = headers[CONTENT_TYPE] === jsonType ? JSON.stringify(body) : body;
    }
  }

  const myRequest = new Request(
    addOrUpdateUrlParams({
      params: queryData,
      url
    }),
    requestOpts
  );

  return Promise.race([
    fetch(myRequest),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout * 1000))
  ]);
};

export default request;
