export const environments = {
  development: {
    isProduction: false,
  },
  staging: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
};

export const current = {
  ...(environments[process.env.REACT_APP_HOST_ENV] || environments.development),
};

export default current;
