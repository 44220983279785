import React, { useEffect, useState, useRef } from 'react';
import ReactCodeInput from 'react-code-input';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import Headline from '#/components/Headline/Headline';
import InputMessage from '#/components/InputMessage/InputMessage';
import ContinueButton from '#/components/ContinueButton/ContinueButton';
import {
  ACCESSIBILITY_LABEL,
  CODE_INPUT_PAGE,
  ERROR_MESSAGE,
  STORAGE,
  ERROR_REQUEST_DESCRIPTION,
  REQUESTS_TYPE
} from '#/constants';
import openLoginRedirect from '#/helpers/openLoginRedirect';
import storage from '#/services/storage/storage';

import styles from './CodeInput.module.scss';

const { CODE_INPUT_CONTAINER, CODE_INPUT_FIELD } = ACCESSIBILITY_LABEL;
const { CODE_INVALID, CORS } = ERROR_MESSAGE;

const {
 HEADLINE, DESCRIPTION, CONTINUE_BUTTON, SIGNUP
} = CODE_INPUT_PAGE;

const { UNKNOWN_LOGIN_CODE } = ERROR_REQUEST_DESCRIPTION;

const { TOKEN } = REQUESTS_TYPE;

function CodeInput() {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [cssClass, setClassName] = useState(styles.input);
  const location = useLocation();
  const codeInputContainerRef = useRef();

  useEffect(() => {
    const { state } = location;

    if (state) {
      if (
        Object.values(ERROR_REQUEST_DESCRIPTION).indexOf(
          state.error_description
        ) > -1
        && state.error_description === UNKNOWN_LOGIN_CODE
      ) {
        setMessage(CODE_INVALID);
      }

      if (state.req === TOKEN) {
        setMessage(CORS);
      }
    }

    const codeInputContainer = document.getElementsByClassName('react-code-input')[0];
    const textInputs = codeInputContainerRef.current.textInput;

    codeInputContainer.setAttribute('aria-label', `${CODE_INPUT_CONTAINER}`);
    _.forEach(textInputs, (textInput, i) => {
      textInput.setAttribute(
        'aria-label',
        CODE_INPUT_FIELD.replace(/#/, i + 1)
      );
    });
  }, []);

  useEffect(() => {
    const loginCodeStorage = storage.get(STORAGE.STORAGE_LOGIN_CODE);
    const textInputs = codeInputContainerRef.current.textInput;

    if (loginCodeStorage && cssClass === styles.error) {
      _.forEach(textInputs, (textInput, i) => {
        // eslint-disable-next-line no-param-reassign
        textInput.value = loginCodeStorage.charAt(i);
      });
      textInputs[0].focus();
      textInputs[0].select();
    }
  }, [message, cssClass]);

  useEffect(() => {
    if (message) {
      setClassName(styles.error);
    }

    const timer = setTimeout(() => {
      setMessage('');
    }, 8000);

    return () => clearTimeout(timer);
  }, [message]);

  const inputRegex = /^[0-9A-Z]{8}$/;
  const MAX_CODE_INPUT = 8;

  const onInputChange = (input) => {
    setCode(input.toUpperCase());
    setClassName(styles.input);
  };

  const continueOnClick = () => {
    storage.remove(STORAGE.STORAGE_LOGIN_CODE);

    if (code.length < MAX_CODE_INPUT) {
      setMessage(ERROR_MESSAGE.CODE_INCOMPLETE);
    } else if (!code.match(inputRegex)) {
      setMessage(ERROR_MESSAGE.CODE_INVALID);
    } else {
      setMessage('');
      // Save login code value to localstorage to use after login redirect back to this webapp
      storage.set(STORAGE.STORAGE_LOGIN_CODE, code);

      // open SAM3 login page for login/redirect to this webapp with token code
      if (storage.get(STORAGE.STORAGE_LOGIN_CODE)) {
        openLoginRedirect();
      } else {
        setMessage(ERROR_MESSAGE.CODE_SAVE);
      }
    }
  };

  return (
    <div className={styles.codeInputContainer}>
      <div className={styles.fixed}>
        <div className={styles.container}>
          <Headline description={DESCRIPTION} headline={HEADLINE} />
          <ReactCodeInput
            ref={codeInputContainerRef}
            className={cssClass}
            fields={`${MAX_CODE_INPUT}`}
            onChange={onInputChange}
            type="text"
          />
          <InputMessage message={message} open={message !== ''} />
          <ContinueButton
            ariaLabel={ACCESSIBILITY_LABEL.CONTINUE_BUTTON}
            onClick={continueOnClick}
            text={CONTINUE_BUTTON}
          />
          <div className={styles.signup}>
            <a href="https://meinkonto.telekom-dienste.de/telekom/account/registration/assistant/index.xhtml">
              {SIGNUP}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

CodeInput.propTypes = {};

export default CodeInput;
