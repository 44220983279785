import flattenDeep from 'lodash/flattenDeep';

export const joinArrayIgnoreInvalid = (items = [], delimiter = '') => flattenDeep(items).filter(Boolean).join(delimiter);

const encodeQueryData = (data = {}) => {
  const queries = Object.entries(data).map(
    ([key, value]) => toString(value) && [key, encodeURIComponent(value)].join('=')
  );

  return joinArrayIgnoreInvalid(queries, '&');
};

export default encodeQueryData;
