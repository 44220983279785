import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import appHistory from '#/config/appHistory';
import ViewContainer from '#/views/ViewContainer';
import styles from './app.scss';

function App() {
  return (
    <div className={styles.root} role="main">
      <Router history={appHistory}>
        <ViewContainer />
      </Router>
    </div>
  );
}

export default App;
