import React from 'react';

import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.list} role="contentinfo">
        <div className={`${styles.fixed} ${styles.clearfix}`}>
          <div className={styles.left}>
            <div className={styles.copyright}>© Telekom Deutschland GmbH</div>
            <div
              className={styles.version}
            >
{`v${process.env.REACT_APP_VERSION}`}
            </div>
          </div>
          <div className={styles.right} role="complementary">
            <ul className={styles.list}>
              <li>
                <a
                  href="https://www.telekom.de/start/impressum"
                  rel="noreferrer"
                  target="_blank"
                >
                  Impressum
                </a>
              </li>
              <li>
                <a
                  href="https://www.telekom.de/agb"
                  rel="noreferrer"
                  target="_blank"
                >
                  AGB
                </a>
              </li>
              <li>
                <a
                  href="https://telekomhilft.telekom.de/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Hilfe & FAQ
                </a>
              </li>
              <li>
                <a
                  href="https://www.telekom.de/kontakt"
                  rel="noreferrer"
                  target="_blank"
                >
                  Kontakt
                </a>
              </li>
              <li>
                <a
                  href="https://www.telekom.de/datenschutzhinweise/download/171.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Datenschutz
                </a>
              </li>
              <li>
                <a
                  href="https://www.telekom.de/magenta-tv"
                  rel="noreferrer"
                  target="_blank"
                >
                  Über MagentaTV
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;
