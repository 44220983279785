import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Headline from '#/components/Headline/Headline';
import ContinueButton from '#/components/ContinueButton/ContinueButton';
import {
  CONFIRMATION_PAGE,
  STORAGE,
  ERROR_REQUEST_DESCRIPTION,
  DT_WEB_URL,
  REQUESTS_TYPE
} from '#/constants';
import useQuery from '#/helpers/useQuery';
import getToken from '#/helpers/getToken';
import completeBackchannelAuth from '#/helpers/completeBackchannelAuth';
import openLoginRedirect from '#/helpers/openLoginRedirect';
import storage from '#/services/storage/storage';

import styles from './Confirmation.module.scss';

const { HEADLINE, CONFIRM_BUTTON } = CONFIRMATION_PAGE;

const { ACCESS_TOKEN_EXPIRED, ACCESS_TOKEN_USED } = ERROR_REQUEST_DESCRIPTION;

const { BC_AUTH, TOKEN } = REQUESTS_TYPE;

function Confirmation() {
  const [completeStatus, setStatus] = useState(false);
  const query = useQuery();
  const tokenCode = query.get('code');
  const loginCode = storage.get(STORAGE.STORAGE_LOGIN_CODE);
  const history = useHistory();

  const redirectToCodeInput = (errorDescription = null, req = null) => {
    if (errorDescription) {
      history.push('/', { error_description: errorDescription, req });
    } else {
      history.push('/', { req });
    }
  };

  useEffect(() => {
    async function backChannelAuth(params) {
      const token = await getToken(params);

      if (token.ok) {
        const results = await completeBackchannelAuth(token, loginCode);

        if (results.error) {
          if (
            params.error_description === ACCESS_TOKEN_EXPIRED
            || params.error_description === ACCESS_TOKEN_USED
          ) {
            openLoginRedirect();
          }

          redirectToCodeInput(results.error_description, BC_AUTH);
        }

        // Authentication done
        setStatus(results.ok);
      } else if (!token.error_description) {
        redirectToCodeInput('', TOKEN);
      } else if (token.error) {
        openLoginRedirect();
      }
    }

    if (tokenCode && loginCode) {
      backChannelAuth({ tokenCode, loginCode });
    } else if (tokenCode && !loginCode) {
      redirectToCodeInput();
    }
  }, [tokenCode, loginCode]);

  useEffect(() => {
    if (completeStatus) {
      // remove stored login code on authentication confirmation
      storage.remove(STORAGE.STORAGE_LOGIN_CODE);
    }
  }, [completeStatus]);

  const continueOnClick = () => {
    window.open(DT_WEB_URL, '_self');
  };

  return (
    <div>
      <div className={styles.fixed}>
        <div className={styles.container}>
          <Headline headline={completeStatus ? HEADLINE : ''} />
          <ContinueButton
            ariaLabel={CONFIRM_BUTTON}
            onClick={continueOnClick}
            text={CONFIRM_BUTTON}
          />
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = {};

export default Confirmation;
