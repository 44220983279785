import React from 'react';
import PropTypes from 'prop-types';
import { HEADER } from '#/constants';
import styles from './Headline.module.scss';

const { MAGENTA_TV } = HEADER;

function Headline({ headline, description }) {
  return (
    <div>
      <div className={styles.brand}>
        <h4 className={styles.title}>{MAGENTA_TV}</h4>
      </div>
      <h1 className={styles.headline}>{headline}</h1>
      <div className={styles.description} role="article">
        {description}
      </div>
    </div>
  );
}

Headline.propTypes = {
  description: PropTypes.string,
  headline: PropTypes.string
};

export default Headline;
