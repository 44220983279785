import _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isNaN from 'lodash/isNaN';

import * as requestModule from './request';

const SUCCESS_RETCODE = '0';
const REQUEST_ERROR = {
  TIMEOUT: 'timeout',
  PARSE_ERROR: 'parse_error'
};
const BC_REQUEST = '/bc-auth/end';

// eslint-disable-next-line no-confusing-arrow
const getHeader = ({ headers = {}, key } = {}) => _isFunction(_get(headers, 'get')) ? headers.get(key) : undefined;

const setMaxAge = (value) => {
  const maxAge = parseInt(value, 10);

  return !_isNaN(maxAge) ? maxAge : null;
};

const valueValidators = {
  maxAge: setMaxAge
};

const getHeaderValueObject = (header = '', validators = {}) => header.split(',').reduce((accumulator, currentValue) => {
    const [propertyName, value] = currentValue.split('=');
    const camelCaseKey = _camelCase(propertyName);
    const validator = validators[camelCaseKey];

    return {
      ...accumulator,
      [camelCaseKey]: _isFunction(validator) ? validator(value) : value
    };
  }, {});

/**
 * @param {Object} [headers] Header object returned from the request
 * @param {Object} [validators] methods used to validate or update the value received from the headers
 * @param {String} [key] used to retrieve the type of header needed to parse
 * @returns {Object} Parsed header values based on a given header key
 */
const getHeaderValues = ({ headers = {}, validators = {}, key } = {}) => {
  const header = getHeader({ headers, key });

  if (!header) {
    return null;
  }

  return getHeaderValueObject(header, validators);
};

const getAltCacheControlHeaderValues = ({ key, validators, headers }) => {
  const header = getHeader({ headers, key }) || '';

  if (!header.toLowerCase().startsWith('cache-control:')) {
    return {};
  }

  return getHeaderValueObject(header.split(':')[1], validators);
};

const parseHeaders = ({ headers } = {}) => {
  const params = {
    key: 'Cache-control',
    validators: valueValidators,
    headers
  };

  const cacheControl = getHeaderValues(params)
    || getAltCacheControlHeaderValues({ ...params, key: 'server' });

  return {
    ...cacheControl,
    eTag: getHeader({ key: 'ETag', headers })
  };
};

const isRequestError = (status) => status === REQUEST_ERROR.TIMEOUT || status === REQUEST_ERROR.PARSE_ERROR;

const isError = (status, retcode, response = {}) => isRequestError(status)
  || !response.ok
  || (retcode && retcode !== SUCCESS_RETCODE);

const doRequest = async (requestOpts) => {
  const { opts = {} } = requestOpts;
  const { parseResponseForErrors = [] } = opts;

  let response;

  let json;

  try {
    response = await requestModule.request(requestOpts);

    if (!response.ok && !parseResponseForErrors.includes(response.status)) {
      throw new Error(`HTTP ${response.statusText}`);
    }

    if (response.url.includes(BC_REQUEST) && response.status === 204) {
      json = { status: response.status };
    } else {
      json = await response.json();
    }
  } catch (e) {
    const errorMessage = _get(e, 'message', '');

    if (errorMessage === REQUEST_ERROR.TIMEOUT) {
      json = { status: REQUEST_ERROR.TIMEOUT };
    } else if (errorMessage.startsWith('HTTP')) {
      const jsonData = await response.json();

      json = { ...jsonData, status: response.status };
    } else {
      json = { status: REQUEST_ERROR.PARSE_ERROR };
    }
  }

  return {
    json,
    response
  };
};

const getOkResponse = (json, headers) => ({
  ...(!Array.isArray(json) ? json : { response: json }),
  __headers: headers,
  ok: true
});

const requestJson = async (requestOpts = {}) => {
  const { endpoint } = requestOpts;

  const { json, response } = await doRequest(requestOpts);

  const { errorCode, status, retcode } = json;
  const headers = requestOpts.includeHeaders ? parseHeaders(response) : null;

  if (!isError(status, retcode, response)) {
    return getOkResponse(json, headers);
  }

  return {
    endpoint,
    errorCode,
    status,
    retcode,
    errorResponse: json,
    isRequestError: isRequestError(status),
    __headers: headers
  };
};

export default requestJson;
