import React from 'react';
import { ACCESSIBILITY_LABEL, HEADER } from '#/constants';

import styles from './Header.module.scss';

const { SLOGAN } = HEADER;

function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.content} role="banner">
        <div className={`${styles.fixed} ${styles.clearfix}`}>
          <div className={styles.left}>
            <span aria-label={ACCESSIBILITY_LABEL.LOGO} className={styles.logo}>
              tT
            </span>
          </div>
          <div className={styles.right}>
            <span
              aria-label={ACCESSIBILITY_LABEL.SLOGAN}
              className={styles.slogan}
            >
              {SLOGAN}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {};

export default Header;
