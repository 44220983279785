import requestJson from '#/helpers/requestJson';
import appConfig from '#/config/app';

const completeBackchannelAuth = async (token, loginCode) => {
  const { sam3 } = appConfig;

  const request = await requestJson({
    endpoint: sam3.authURL,
    opts: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token.access_token}`
      },
      oauth: true,
      body: {
        client_id: sam3.clientID,
        initial_login_code: loginCode
      }
    }
  });

  if (request.ok && request.status === 204) {
    return request;
  }

  return {
    error: request.errorResponse.error || true,
    error_description: request.errorResponse.errorDescription
  };
};

export default completeBackchannelAuth;
