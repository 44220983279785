const PREFIX = 'DTAG_';

const storage = {
  get: (key, defaultValue = null) => {
    const value = localStorage.getItem(PREFIX + key);

    let parsedValue;

    try {
      parsedValue = JSON.parse(value);
    } catch (_) {
      parsedValue = null;
    }

    return parsedValue || defaultValue;
  },

  set: (key, value) => {
    let finalValue;

    try {
      finalValue = JSON.stringify(value);
    } catch (e) {
      finalValue = null;

      throw e;
    }

    localStorage.setItem(PREFIX + key, finalValue);
  },

  remove: (key) => {
    localStorage.removeItem(PREFIX + key);
  }
};

export default storage;
